<template>
  <div
    class="responsive-wrapper"
    style="background: rgb(234 231 231); height: auto"
  >
    <!-- Header Section px-7 py-4-->
    <header class="Publicdonation16d781947Svg">
      <div
        v-if="$vuetify.breakpoint.xsOnly"
        style="position: relative; width: 100%"
      >
        <!-- Mobile Banner -->
        <img
          class=""
          style="width: 100%"
          src="@/assets/thingsToDo/mobbanner2.png"
          alt="Mobile Banner"
        />

        <!-- GPay Icon -->
        <img
          class=""
          style="
            position: absolute;
            bottom: 25%;
            left: 20%;
            width: 25%;
            height: auto;
             cursor:pointer;
          "
          src="@/assets/thingsToDo/gpay.png"
          alt="GPay"
        @click="openGpayStore()" />

        <!-- App Store Icon -->
        <img
          class=""
          style="
            position: absolute;
            bottom: 25%;
            right: 26%;
            width: 25%;
            height: auto;
            cursor:pointer;
          "
          src="@/assets/thingsToDo/appstore.png"
          alt="App Store"
       @click="openAppleStore()" />
      </div>

      <div style="position: relative; width: 99%">
        <!-- Main Web Banner -->
        <img
          v-if="$vuetify.breakpoint.smAndUp"
          class=""
          style="width: 100%"
          src="@/assets/thingsToDo/webbanner3.png"
          alt="Web Banner"
        />

        <!-- GPay Icon -->
        <img
          v-if="$vuetify.breakpoint.smAndUp"
          class=""
          style="
            position: absolute;
            top: 50%;
            left: 38%;
            width: 10%;
            height: auto;
            cursor:pointer;
          "
          src="@/assets/thingsToDo/gpay.png"
          alt="GPay"
        @click="openGpayStore()" />

        <!-- App Store Icon -->
        <img
          v-if="$vuetify.breakpoint.smAndUp"
          class=""
          style="
            position: absolute;
            top: 50%;
            left: 49%;
            width: 10%;
            height: auto;
            cursor:pointer;
          "
          src="@/assets/thingsToDo/appstore.png"
          alt="App Store"
        @click="openAppleStore()" />
      </div>
    </header>
    <div class="container">
      <!-- Video Section -->
      <section class="video-section">
        <h2>What is WizFit Challenge?</h2>
        <a
          :href="'https://video.harlemwizardsinabox.com/introductionvideo.mp4'"
          target="_blank"
        >
          <button class="video-btn">
            <img
              class="playicon"
              src="@/assets/publicDonationPage/playicon.png"
            />
            <span>Watch Video</span>
          </button>
        </a>
      </section>

      <!-- Main Image Section -->
      <section class="main-image-section">
        <img
          src="@/assets/publicDonationPage/player_webpage.png"
          alt="WizFit Character"
        />
      </section>

      <div class="card_area">
        <section class="search-section">
          <input
            v-model="search"
            type="text"
            placeholder="Search Campaigns..."
            class="search-box"
            @keyup.enter="getCampaignManagementData"
          />
        </section>

        <!-- Join Campaign Section -->
        <section
          class="campaign-card-section"
          v-for="item in visibleItems"
          :key="item.id"
        >
          <div class="campaign-card">
            <img
              class="DivVImageImage"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.logo_url"
            />
            <h4
              :title="`${item.city_name}, ${item.state_name}, ${item.school_name}`"
            >
              {{ item.school_name }}, {{ item.city_name }},
              {{ item.state_name }}
            </h4>
            <button
              class="campaign-btn"
              @click="viewPublicDonationPageHandler(item)"
            >
              Join Campaign
            </button>
          </div>
        </section>
        <!-- No Campaign Found Message -->
        <div
          v-if="filteredSchoolList.length === 0 && search.trim().length >= 3"
          class="no-campaign-found"
        >
          No campaign found
        </div>
        <div
          v-if="!showAllItems && schoolList.length > 2"
          class="see-more-container"
        >
          <button @click="showAllItems = true" class="see-more-btn">
            See More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST } from "@/constants/APIUrls";
/*import {
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "../../../constants/urls";**/
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  // components:{ VideoSection: () =>
  //     import("@/components/PublicDonationPages/VideoSection/VideoSectionWizfit.vue"), },
  data() {
    return {
      loading: false,
      items: [],
      schoolList: [],
      adminAccess: localStorage.getItem("adminAccess"),
      search: "",
      showAllItems: false,
      noCampaignMessage: false,
    };
  },
  computed: {
    filteredSchoolList() {
      if (this.search.length > 0) {
        return this.schoolList.filter((item) =>
          `${item.city_name} ${item.state_name} ${item.school_name}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      }
      return this.schoolList;
    },
    visibleItems() {
      if (this.showAllItems) {
        return this.filteredSchoolList;
      }
      return this.filteredSchoolList.slice(0, 2);
    },
  },
  watch: {
    search(newVal) {
      if (newVal.trim() === "") {
        // If input is cleared, load the full list
        this.getCampaignManagementData();
      } else if (newVal.trim().length >= 3) {
        // If 3 or more characters, fetch filtered list
        this.getCampaignManagementData(newVal);
      }
    },
  },
  methods: {
    seeMore() {
      // Increase the visible rows by 1 on each click
      this.visibleRowCount += 1;
    },
    getCampaignManagementData() {
      const self = this;
      self.loading = true;
      this.showAllItems = false;
      const successHandler = (res) => {
        self.schoolList = res.data.school_list;
        self.loading = false;
      };
      const failureHandler = () => {
        self.loading = false;
      };
      let formData = {};
      formData["search"] = this.search;
      Axios.request_GET(
        API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler(item) {
      const { sub_domain } = item;
      Helper.openPublicDonationPage(sub_domain);
    },
    reload() {
      location.reload();
    },
     openAppleStore() {
      window.open("https://apps.apple.com/us/app/harlem-wizards/id6590627343", "_blank");
    },
     openGpayStore() {
      window.open("https://play.google.com/store/apps/details?id=com.inmotion.harlemwizard&pli=1", "_blank");
    },
  },
  mounted() {
    this.getCampaignManagementData();
  },
};
</script>

<style scoped>
.Publicdonation16d781947Svg {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-flex;
}
/* new UI css */
.responsive-wrapper {
  /* margin: auto; */
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  text-align: center;
  padding: 20px;
}

.header-section {
  background-color: #6c63ff;
  color: white;
  padding: 20px;
}

.main-image-section {
  display: flex;
  justify-content: center;
  margin: 29px 0px;
  position: relative;
  margin-top: -4px;
}

.main-image-section img {
  width: auto;
  height: 300px;
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  width: 95%;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-box:disabled {
  background-color: #f0f0f0;
}

.search-box:focus {
  outline: none;
  border-color: #6c63ff;
}

/* Card Area */
.card_area {
  position: relative;
  top: -65px; /* Move the card section up to overlap */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 670px;
  margin: auto;
  text-align: center;
  background-color: #ffff;
  padding: 20px;
  border-radius: 10px;
}

.card_area h3 {
  font-family: "Alfa Slab One";
  color: #d62c53;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.campaign-card-section {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.campaign-card {
  display: flex;
  justify-content: space-between; /* Distribute space between h4 and button */
  align-items: center;
  width: 95%;
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.campaign-card h4 {
  flex: 1; /* Make the h4 take up equal space */
  margin: 0;
  font-size: 16px;
  text-align: left;
}

.campaign-btn {
  flex: 0 1 auto;
  background-color: #ffff;
  color: #f25c5c;
  padding: 10px 20px;
  border: 1px solid #f25c5c;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bolder;
}

.campaign-btn:hover {
  background-color: #d44b4b; /* Darken the button on hover */
}

/* Video Section */
.video-section {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically align */
  margin: 5px 0;
  flex-wrap: wrap;
}

.video-section h2 {
  font-family: "Alfa Slab One";
  font-size: 45px;
  line-height: 24px;
  text-align: left;
  color: #d31e48;
  padding-right: 20px;
}

.video-btn {
  display: flex;
  align-items: center;
  background-color: #d31e48;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-family: "Alfa Slab One";
  font-size: 20px;
  margin-top: 9px;
}

.video-btn:hover {
  background-color: #d31e48;
}

.video-btn img.playicon {
  width: 20px; /* Adjust icon size */
  height: 20px;
  margin-right: 10px;
}

.app_section {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Aligns the content vertically */
  flex-wrap: wrap; /* Makes the layout responsive */
  /* margin: 20px 0; */
}

.app_section h6 {
  margin-right: 20px; /* Adds some space between the heading and images */
  font-size: 18px; /* You can adjust the size */
}

.app-images {
  display: flex;
  gap: 10px; /* Adds space between the app store images */
}

.app-images img {
  width: 120px; /* Adjust the size for responsiveness */
  height: auto;
}

.see-more-container {
  text-align: center;
  margin-top: 10px;
}

.see-more-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.see-more-btn:hover {
  background-color: #0056b3;
}
/* Responsive Styling */

@media screen and (max-width: 768px) {
  .Publicdonation16d781947Svg {
    flex: 1 1 0;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: block;
  }
  .main-image-section img {
    width: 100%;
    height: auto;
  }

  .campaign-card-section {
    flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
    overflow-x: auto; /* Add horizontal scrolling if needed */
  }

  .campaign-card {
    width: auto; /* Allow the card to fit its content */
    flex-direction: row; /* Ensure items stay in one row on mobile */
  }

  .campaign-card h4 {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 15px;
    text-align: left;
    width: 100px;
  }

  .campaign-btn {
    width: auto; /* Keep button auto-sized for its content */
  }

  .video-section {
    flex-direction: column; /* Stack text and button on smaller screens */
    text-align: center;
    margin: 0;
  }

  .video-section h2 {
    text-align: center; /* Center the heading on small screens */
    padding-right: 0; /* Remove right padding on smaller screens */
    margin-bottom: 10px; /* Add some space between the heading and button */
    line-height: 40px;
  }

  .video-btn {
    width: 100%; /* Full-width button on smaller screens */
    justify-content: center; /* Center button content */
  }

  .search-box {
    width: 100%; /* Make input take up more space on smaller screens */
  }

  .app_section {
    flex-direction: column; /* Stack the heading and images vertically on smaller screens */
    text-align: center;
  }

  .app-images img {
    width: 100px; /* Resize images on smaller screens */
  }

  .app_section h6 {
    margin-bottom: 15px; /* Adds space between heading and images in stacked layout */
  }

  .card_area h3 {
    font-family: "Alfa Slab One";
    color: #d62c53;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  .video-section h2 {
    font-family: "Alfa Slab One";
    font-size: 23px;
    margin-top: 0;
  }
}
</style>
