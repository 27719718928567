<template>
  <div class="responsive-wrapper" style="background: white; height: auto">
    <!-- Header Section px-7 py-4-->
  
      <div
        class="desktop-content"
        style="
          width: 1400px;
          height: 900px;
          position: relative;
          background: white;
          margin: 0 auto;
        "
      >
        <div
          style="
            width: 1080.71px;
            height: 5px;
            left: 144.14px;
            top: 272.14px;
            position: absolute;
          "
        ></div>
        <div
          style="
            width: 1012.41px;
            left: 178.3px;
            top: 1117.15px;
            position: absolute;
            text-align: justify;
          "
        >
          <span
            style="
              color: #38227a;
              font-size: 17px;
              font-family: inherit;
              font-weight: 700;
              line-height: 20px;
              word-wrap: break-word;
            "
            >Disclaimer:</span
          ><span
            style="
              color: #38227a;
              font-size: 17px;
              font-family: inherit;
              line-height: 20px;
              word-wrap: break-word;
            "
          >
            For the best experience, the Harlem Wizards highly recommend
            downloading the app. It offers more convenience and enhanced
            features. However, if you prefer, you can access the web version by
            clicking </span
          ><span
            style="
              color: #38227a;
              font-size: 17px;
              font-family: Poppins;
              font-weight: 700;
              text-decoration: underline;
              line-height: 20px;
              word-wrap: break-word;
            "
          >
            <a
              href="/wizfitchallenge2"
              target="_blank"
              rel="noopener noreferrer"
              style="font-family: 'Roboto'; font-weight: 700; color: #38227a"
            >
              here
            </a></span
          >
        </div>
        <div
          style="
            width: 1186.21px;
            height: 207.05px;
            left: 76.83px;
            top: 49.55px;
            position: absolute;
          "
        >
          <div
            style="
              width: 1068.01px;
              height: 170.72px;
              left: 118.21px;
              top: 16.69px;
              position: absolute;
              background: #3e2384;
            "
          ></div>
          <img
            style="
              width: 188.85px;
              height: 81.22px;
              left: 998px;
              top: 59.83px;
              position: absolute;
              transform-origin: 0 0;
            "
            src="@/assets/thingsToDo/rightarrow.png"
          />
          <img
            style="
              width: 236.41px;
              height: 207.05px;
              left: 0px;
              top: 0px;
              position: absolute;
            "
            src="@/assets/thingsToDo/logowizfit.png"
          />
          <div
            style="
              height: 120.13px;
              left: 378.47px;
              top: 38.38px;
              position: absolute;
              text-align: center;
              color: #ffa723;
              font-size: 50px;
              font-family: Oswald;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 60px;
              word-wrap: break-word;
            "
          >
            IT’S TIME TO TAKE<br />THE WIZFIT CHALLENGE
          </div>
        </div>
        <div
          style="
            width: 693.16px;
            height: 26px;
            left: 337.92px;
            top: 259.97px;
            position: absolute;
            text-align: center;
            color: #38227a;
            font-size: 26px;
            font-family: Poppins;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 35px;
            word-wrap: break-word;
          "
        >
          register for your school campaign today!
        </div>
        <div
          style="
            width: 457.38px;
            height: 85.38px;
            left: 626.05px;
            top: 583.77px;
            position: absolute;
            text-align: center;
            color: #38227a;
            font-size: 38px;
            font-family: Alfa Slab One;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 40px;
            word-wrap: break-word;
          "
        >
          Don’t Wait!<br />download the app
        </div>
        <img
          style="
            width: 327.24px;
            height: 522.73px;
            left: 938.8px;
            top: 316.82px;
            position: absolute;
          "
          src="@/assets/thingsToDo/player.png"
        />
        <div
          style="
            width: 702.95px;
            height: 118.43px;
            left: 333.03px;
            top: 310.6px;
            position: absolute;
          "
        >
          <img
            style="
              width: auto;
              height: 85px;
              left: 50px;
              top: 15px;
              position: absolute;
              cursor: pointer;
            "
            src="@/assets/thingsToDo/appstore.png"
            @click="openAppleStore()"
          />
          <img
            style="
              width: auto;
              height: 85px;
              left: 350px;
              top: 15px;
              position: absolute;
              cursor: pointer;
            "
            src="@/assets/thingsToDo/gpay.png"
            @click="openGpayStore()"
          />
        </div>
        <div
          style="
            width: 1000.53px;
            height: 214.4px;
            left: 178.3px;
            top: 872.59px;
            position: absolute;
            background: #efebff;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
            border-radius: 18px;
            border: 2px #38227a solid;
          "
        ></div>
        <div
          style="
            width: 354.48px;
            height: 25.34px;
            left: 205.48px;
            top: 921.97px;
            position: absolute;
            color: #38227a;
            font-size: 28px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 14px;
            word-wrap: break-word;
          "
        >
          &bull; Easy and Quick Signup
        </div>
        <div
          style="
            width: 469.77px;
            height: 24px;
            left: 205.48px;
            top: 997.73px;
            position: absolute;
            color: #38227a;
            font-size: 28px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 34px;
            word-wrap: break-word;
          "
        >
          &bull; Interactive Features to <br />&nbsp;&nbsp; Boost Student
          Engagement
        </div>
        <div
          style="
            width: 389.42px;
            left: 660px;
            top: 1022.73px;
            position: absolute;
            color: #38227a;
            font-size: 28px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 14px;
            word-wrap: break-word;
          "
        >
          &bull; Simplified Sharing Tools
        </div>
        <div
          style="
            width: 532.07px;
            left: 660px;
            top: 898.97px;
            position: absolute;
            color: #38227a;
            font-size: 28px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 34px;
            word-wrap: break-word;
          "
        >
          &bull; Lifetime Access to an Extensive Workout <br />&nbsp;&nbsp;
          Video Library
        </div>
        <div
          style="
            width: 390.45px;
            height: 0px;
            left: 210px;
            top: 978px;
            position: absolute;
            border: 0.5px #38227a solid;
          "
        ></div>
        <div
          style="
            width: 488.14px;
            height: 0px;
            left: 665px;
            top: 978px;
            position: absolute;
            border: 0.5px #38227a solid;
          "
        ></div>
        <div
          style="
            width: 477.98px;
            height: 397.82px;
            left: 144.14px;
            top: 449.2px;
            position: absolute;
          "
        >
          <div
            style="
              width: 477.98px;
              height: 397.82px;
              left: 0px;
              top: 0px;
              position: absolute;
            "
          >
            <img
              style="width: auto; height: 400px; top: 0px; position: absolute"
              src="@/assets/thingsToDo/downloadapp1.png"
            />
          </div>
        </div>
      </div>

      <div
        class="mobile-content"
        style="
          width: 100%;
          min-height: 100vh;
          background: white;
          margin: 0 auto;
          position: relative;
        "
        v-if="$vuetify.breakpoint.xsOnly"
      >
        <div
          style="
            width: 382.15px;
            height: 81.47px;
            left: 7.94px;
            top: 42.1px;
            position: absolute;
          "
        >
          <div
            style="
              width: 340.05px;
              height: 65.52px;
              left: 42.1px;
              top: 6.57px;
              position: absolute;
              background: #3e2384;
            "
          ></div>
          <img
            style="
              width: 70.23px;
              height: 42.33px;
              left: 312px;
              top: 18.16px;
              position: absolute;
              transform-origin: 0 0;
            "
            src="@/assets/thingsToDo/rightarrow.png"
          />
          <img
            style="
              width: 93.02px;
              height: 81.47px;
              left: 0px;
              top: 0px;
              position: absolute;
            "
            src="@/assets/thingsToDo/logowizfit.png"
          />
          <div
            style="
              height: 47.27px;
              left: 109.51px;
              top: 13.88px;
              position: absolute;
              text-align: center;
              color: #ffa723;
              font-size: 16px;
              font-family: Oswald;
              font-weight: 700;
              text-transform: uppercase;
              line-height: 24px;
              word-wrap: break-word;
            "
          >
            IT’S TIME TO TAKE<br />THE WIZFIT CHALLENGE
          </div>
        </div>
        <div
          style="
            width: 368.26px;
            height: 62.04px;
            left: 45.22px;
            top: 160.59px;
            position: absolute;
          "
        >
          <img
            style="
              width: auto;
              height: 55px;
              left: 0px;
              top: 25px;
              position: absolute;
              cursor: pointer;
            "
            src="@/assets/thingsToDo/appstore.png"
            @click="openAppleStore()"
          />
          <img
            style="
              width: auto;
              height: 55px;
              left: 180px;
              top: 25px;
              position: absolute;
              cursor: pointer;
            "
            src="@/assets/thingsToDo/gpay.png"
            @click="openGpayStore()"
          />
        </div>
        <div
          style="
            height: 26px;
            left: 45.9px;
            top: 136.08px;
            position: absolute;
            text-align: center;
            color: #38227a;
            font-size: 14px;
            font-family: 'Oswald';
            font-weight: 600;
            text-transform: uppercase;
            line-height: 35px;
            word-wrap: break-word;
          "
        >
          register for your school campaign today!
        </div>
        <div
          style="
            width: 360.48px;
            height: 300.03px;
            left: 21px;
            top: 261.16px;
            position: absolute;
          "
        >
          <div
            style="
              width: 360.48px;
              height: 300.03px;
              left: 15px;
              top: 0px;
              position: absolute;
            "
          >
            <img
              style="width: auto; height: 310px; position: absolute"
              src="@/assets/thingsToDo/downloadapp1.png"
            />
          </div>
        </div>
        <div
          style="
            width: 370.27px;
            height: 85.38px;
            left: 35.83px;
            top: 567.5px;
            position: absolute;
            text-align: center;
            color: #38227a;
            font-size: 20px;
            font-family: Alfa Slab One;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 40px;
            word-wrap: break-word;
          "
        >
          Don’t Wait!<br />download the app
        </div>
        <div
          style="
            width: 368.26px;
            height: 244.22px;
            left: 35.87px;
            top: 675.45px;
            position: absolute;
            background: #efebff;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
            border-radius: 18px;
            border: 2px #38227a solid;
          "
        ></div>
        <div
          style="
            width: 341.64px;
            height: 25.34px;
            left: 45.27px;
            top: 687.43px;
            position: absolute;
            color: #38227a;
            font-size: 20px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 14px;
            word-wrap: break-word;
            text-align: left; /* Left align */
          "
        >
          &bull; Easy and Quick Signup
        </div>
        <div
          style="
            width: 341.64px;
            height: 24px;
            left: 45.27px;
            top: 786.92px;
            position: absolute;
            color: #38227a;
            font-size: 20px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 28px;
            word-wrap: break-word;
            text-align: left; /* Left align */
          "
        >
          &bull; Interactive Features to Boost  &nbsp;&nbsp;&nbsp; Student
       Engagement
        </div>
        <div
          style="
            width: 341.64px;
            left: 45.27px;
            top: 858.93px;
            position: absolute;
            color: #38227a;
            font-size: 20px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 14px;
            word-wrap: break-word;
            text-align: left; /* Left align */
          "
        >
          &bull; Simplified Sharing Tools
        </div>
        <div
          style="
            width: 341.64px;
            left: 45.27px;
            top: 721.71px;
            position: absolute;
            color: #38227a;
            font-size: 20px;
            font-family: 'Oswald';
            font-weight: 500;
            line-height: 28px;
            word-wrap: break-word;
            text-align: left; /* Left align */
          "
        >
          &bull; Lifetime Access to an Extensive &nbsp; &nbsp;Workout
         Video Library
        </div>

        <div
          style="
            width: 368.26px;
               left: 45px;
            top: 940.22px;
            position: absolute;
            text-align: justify;
            padding-bottom: 20px;
          "
        >
          <span
            style="
              color: #38227a;
              font-size: 18px;
              font-family: inherit;
              font-weight: 700;
              line-height: 20px;
              word-wrap: break-word;
            "
            >Disclaimer:</span
          ><span
            style="
              color: #38227a;
              font-size: 18px;
              font-family: inherit;
              line-height: 20px;
              word-wrap: break-word;
            "
          >
            For the best experience, the Harlem Wizards highly recommend
            downloading the app. It offers more convenience and enhanced
            features. However, if you prefer, you can access the web version by
            clicking </span
          ><span
            style="
              color: #38227a;
              font-size: 18px;
              font-family: Poppins;
              font-weight: 700;
              text-decoration: underline;
              line-height: 20px;
              word-wrap: break-word;
            "
          >
            <a
              href="/wizfitchallenge2"
              target="_blank"
              rel="noopener noreferrer"
              style="font-family: 'Roboto'; font-weight: 700; color: #38227a"
            >
              here
            </a></span
          >
        </div>
      </div>
      <!-- <div class="container">
    
      <section class="video-section">
        <h2>What is WizFit Challenge?</h2>
        <a
          :href="'https://video.harlemwizardsinabox.com/introductionvideo.mp4'"
          target="_blank"
        >
          <button class="video-btn">
            <img
              class="playicon"
              src="@/assets/publicDonationPage/playicon.png"
            />
            <span>Watch Video</span>
          </button>
        </a>
      </section>

     
      <section class="main-image-section">
        <img
          src="@/assets/publicDonationPage/player_webpage.png"
          alt="WizFit Character"
        />
      </section>

      <div class="card_area">
        <section class="search-section">
          <input
            v-model="search"
            type="text"
            placeholder="Search Campaigns..."
            class="search-box"
            @keyup.enter="getCampaignManagementData"
          />
        </section>

      
        <section
          class="campaign-card-section"
          v-for="item in visibleItems"
          :key="item.id"
        >
          <div class="campaign-card">
            <img
              class="DivVImageImage"
              style="width: 40px; height: 40px; margin-right: 10px"
              :src="item.logo_url"
            />
            <h4
              :title="`${item.city_name}, ${item.state_name}, ${item.school_name}`"
            >
              {{ item.school_name }}, {{ item.city_name }},
              {{ item.state_name }}
            </h4>
            <button
              class="campaign-btn"
              @click="viewPublicDonationPageHandler(item)"
            >
              Join Campaign
            </button>
          </div>
        </section>
      
        <div
          v-if="filteredSchoolList.length === 0 && search.trim().length >= 3"
          class="no-campaign-found"
        >
          No campaign found
        </div>
        <div
          v-if="!showAllItems && schoolList.length > 2"
          class="see-more-container"
        >
          <button @click="showAllItems = true" class="see-more-btn">
            See More
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST } from "@/constants/APIUrls";
/*import {
  CAMPAIGN_ID,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "../../../constants/urls";**/
import Helper from "@/helper";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  // components:{ VideoSection: () =>
  //     import("@/components/PublicDonationPages/VideoSection/VideoSectionWizfit.vue"), },
  data() {
    return {
      loading: false,
      items: [],
      schoolList: [],
      adminAccess: localStorage.getItem("adminAccess"),
      search: "",
      showAllItems: false,
      noCampaignMessage: false,
    };
  },
  computed: {
    filteredSchoolList() {
      if (this.search.length > 0) {
        return this.schoolList.filter((item) =>
          `${item.city_name} ${item.state_name} ${item.school_name}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      }
      return this.schoolList;
    },
    visibleItems() {
      if (this.showAllItems) {
        return this.filteredSchoolList;
      }
      return this.filteredSchoolList.slice(0, 2);
    },
  },
  watch: {
    search(newVal) {
      if (newVal.trim() === "") {
        // If input is cleared, load the full list
        this.getCampaignManagementData();
      } else if (newVal.trim().length >= 3) {
        // If 3 or more characters, fetch filtered list
        this.getCampaignManagementData(newVal);
      }
    },
  },
  methods: {
    seeMore() {
      // Increase the visible rows by 1 on each click
      this.visibleRowCount += 1;
    },
    getCampaignManagementData() {
      const self = this;
      self.loading = true;
      this.showAllItems = false;
      const successHandler = (res) => {
        self.schoolList = res.data.school_list;
        self.loading = false;
      };
      const failureHandler = () => {
        self.loading = false;
      };
      let formData = {};
      formData["search"] = this.search;
      Axios.request_GET(
        API_ADMIN_GET_CAMPAIGN_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    viewPublicDonationPageHandler(item) {
      const { sub_domain } = item;
      Helper.openPublicDonationPage(sub_domain);
    },
    reload() {
      location.reload();
    },
    openAppleStore() {
      window.open(
        "https://apps.apple.com/us/app/harlem-wizards/id6590627343",
        "_blank"
      );
    },
    openGpayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.inmotion.harlemwizard&pli=1",
        "_blank"
      );
    },
  },
  mounted() {
    this.getCampaignManagementData();
  },
};
</script>

<style scoped>
.Publicdonation16d781947Svg {
  flex: 1 1 0;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-flex;
}
/* new UI css */
.responsive-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Mobile view: Adjust styles for screens smaller than 480px */
@media (max-width: 480px) {
  .responsive-wrapper {
    flex-direction: column; /* Keeps the column layout */
    align-items: flex-start; /* Aligns content to the left */
    padding: 10px; /* Adds some padding */
    width: 100%;
  }
}

.container {
  text-align: center;
  padding: 20px;
}

.header-section {
  background-color: #6c63ff;
  color: white;
  padding: 20px;
}

.main-image-section {
  display: flex;
  justify-content: center;
  margin: 29px 0px;
  position: relative;
  margin-top: -4px;
}

.main-image-section img {
  width: auto;
  height: 300px;
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  width: 95%;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.search-box:disabled {
  background-color: #f0f0f0;
}

.search-box:focus {
  outline: none;
  border-color: #6c63ff;
}

/* Card Area */
.card_area {
  position: relative;
  top: -65px; /* Move the card section up to overlap */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 670px;
  margin: auto;
  text-align: center;
  background-color: #ffff;
  padding: 20px;
  border-radius: 10px;
}

.card_area h3 {
  font-family: "Alfa Slab One";
  color: #d62c53;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.campaign-card-section {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.campaign-card {
  display: flex;
  justify-content: space-between; /* Distribute space between h4 and button */
  align-items: center;
  width: 95%;
  padding: 10px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}

.campaign-card h4 {
  flex: 1; /* Make the h4 take up equal space */
  margin: 0;
  font-size: 16px;
  text-align: left;
}

.campaign-btn {
  flex: 0 1 auto;
  background-color: #ffff;
  color: #f25c5c;
  padding: 10px 20px;
  border: 1px solid #f25c5c;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bolder;
}

.campaign-btn:hover {
  background-color: #d44b4b; /* Darken the button on hover */
}

/* Video Section */
.video-section {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically align */
  margin: 5px 0;
  flex-wrap: wrap;
}

.video-section h2 {
  font-family: "Alfa Slab One";
  font-size: 45px;
  line-height: 24px;
  text-align: left;
  color: #d31e48;
  padding-right: 20px;
}

.video-btn {
  display: flex;
  align-items: center;
  background-color: #d31e48;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-family: "Alfa Slab One";
  font-size: 20px;
  margin-top: 9px;
}

.video-btn:hover {
  background-color: #d31e48;
}

.video-btn img.playicon {
  width: 20px; /* Adjust icon size */
  height: 20px;
  margin-right: 10px;
}

.app_section {
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Aligns the content vertically */
  flex-wrap: wrap; /* Makes the layout responsive */
  /* margin: 20px 0; */
}

.app_section h6 {
  margin-right: 20px; /* Adds some space between the heading and images */
  font-size: 18px; /* You can adjust the size */
}

.app-images {
  display: flex;
  gap: 10px; /* Adds space between the app store images */
}

.app-images img {
  width: 120px; /* Adjust the size for responsiveness */
  height: auto;
}

.see-more-container {
  text-align: center;
  margin-top: 10px;
}

.see-more-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.see-more-btn:hover {
  background-color: #0056b3;
}
/* Responsive Styling */

@media screen and (max-width: 768px) {
  .Publicdonation16d781947Svg {
    flex: 1 1 0;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: block;
  }
  .main-image-section img {
    width: 100%;
    height: auto;
  }

  .campaign-card-section {
    flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
    overflow-x: auto; /* Add horizontal scrolling if needed */
  }

  .campaign-card {
    width: auto; /* Allow the card to fit its content */
    flex-direction: row; /* Ensure items stay in one row on mobile */
  }

  .campaign-card h4 {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 15px;
    text-align: left;
    width: 100px;
  }

  .campaign-btn {
    width: auto; /* Keep button auto-sized for its content */
  }

  .video-section {
    flex-direction: column; /* Stack text and button on smaller screens */
    text-align: center;
    margin: 0;
  }

  .video-section h2 {
    text-align: center; /* Center the heading on small screens */
    padding-right: 0; /* Remove right padding on smaller screens */
    margin-bottom: 10px; /* Add some space between the heading and button */
    line-height: 40px;
  }

  .video-btn {
    width: 100%; /* Full-width button on smaller screens */
    justify-content: center; /* Center button content */
  }

  .search-box {
    width: 100%; /* Make input take up more space on smaller screens */
  }

  .app_section {
    flex-direction: column; /* Stack the heading and images vertically on smaller screens */
    text-align: center;
  }

  .app-images img {
    width: 100px; /* Resize images on smaller screens */
  }

  .app_section h6 {
    margin-bottom: 15px; /* Adds space between heading and images in stacked layout */
  }

  .card_area h3 {
    font-family: "Alfa Slab One";
    color: #d62c53;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
  }
  .video-section h2 {
    font-family: "Alfa Slab One";
    font-size: 23px;
    margin-top: 0;
  }
}
@media (min-width: 401px) and (max-width: 767px) {
  .mobile-content {
    display: block;
    zoom: 75%;
  }
  .desktop-content {
    display: none !important;
  }
}
@media (max-width: 400px) {
  .mobile-content {
    display: block;
    zoom: 70%;
  }
  .desktop-content {
    display: none !important;
  }
}
@media (max-width: 375px) {
  .mobile-content[data-v-8c5805ac] {
    display: block;
    transform: scale(0.7); /* More consistent than zoom */
    /* justify-content: center; */
  }
}

/* Target iPhone X, XR, 11 */
@media (max-width: 414px) {
  .mobile-content[data-v-8c5805ac] {
    display: block;
    transform: scale(0.8); /* Adjust scaling for larger screens */
  }
}

@media (min-width: 1440px) {
  body {
    justify-items: center;
  }
}
.v-application--wrap {
  background: white !important;
}
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

body {
  font-family: "Oswald", sans-serif;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .desktop-content {
    display: block;
    zoom: 60%;
  }
  .mobile-content {
    display: none !important;
  }
}
@media (min-width: 585px) and (max-width: 767px) {
  .desktop-content {
  display: block !important;
    zoom: 45%;
 }
}
</style>
